<template>
  <div id="nPopUp" style="display: block; position: fixed; top: 0px; left: 0px;">
    <div style="width: 80%; height: 90%;">
      <img :src="signatureImageUrl" alt="signature image" class="signatureImage">
      <span id="nPopUpClose" @click="closeModal">x</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'signature-pop-up-viewer',
  props: ['signatureImageUrl'],
  methods: {
    closeModal() {
      this.$emit('modal_close');
    },
  },
};
</script>

<style scoped>
  #nPopUp{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    z-index: 99999;
    font-family: 'Source Sans Pro', Verdana, sans-serif;
    line-height: 23px;
  }
  #nPopUp div{
    padding: 10px 0 10px 10px;
    max-width: 1024px;
    width: 80%;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-shadow: 0px 2px 6px rgba(0,0,0,1);
    border-radius: 3px;
    background: rgb(255, 255, 255);
  }
  #nPopUp #nPopUpClose{
    width: 25px;
    height: 25px;
    display: inline-block;
    position: absolute;
    top: 0px;
    right: 0px;
    transition: ease 0.25s all;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    border-radius: 1000px;
    background: rgba(0,0,0,0.7);
    font-size: 20px;
    text-align: center;
    text-decoration: none;
    line-height: 100%;
    color: #fff;
    cursor: pointer;
  }
  .signatureImage{
    max-width: 90%;
    max-height: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
</style>
