























import Vue from 'vue';

export default Vue.extend({
  name: 'contact',
  mounted() {
    this.$emit('onId', '');
    this.$emit('getCustomerData', '');
  },
});
